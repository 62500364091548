import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import kv from '../images/message-kv.jpg'
import signature from '../images/signature.svg'

const Message = ({ location }) => (
  <Layout location={location} title="Message" subTitle="代表メッセージ" kv={kv}>
    <SEO title="代表メッセージ" />
    <section className="message-content">
      <pre>
      近年日本経済の落ち込みは著しく、<br />
      政府による増税、各種公共料金の値上げなどにより<br />
      国民の家計は年々厳しくなってきております。<br />
      弊社はこうした厳しい時代の中でお客様に「賢い生活」を<br />
      送り続けていただく為、様々なサービスを扱っております。<br />
      我々スマートホームが目指すのは、お客様との「末永いお付き合い」です。<br />
      その為に我々一同日々謙虚な姿勢でお客様にとっての「賢い生活」を追求し続けていきます。
      </pre>
      <small>
        <img src={signature} alt="孫 寛貴" />
      </small>
    </section>
  </Layout>
)

export default Message
